<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <div v-if="enquiryReservations.enquiryReservationsData">
      <EnquiryReservationsTable
        :enquiryReservationsData="enquiryReservations.enquiryReservationsData"
        v-on:setEnquiryReservationData="enquiryReservationData.fillData($event)"
        v-on:setReservationData="setReservationData($event)"
        :enquiryReservationData="enquiryReservationData"
      />

      <Pagination
        v-if="!isLoading"
        :paginationData="enquiryReservations.pagination"
        v-on:changePage="changePage"
      />

      <EnquiryReservationDelete
        :enquiryReservationData="enquiryReservationData"
        v-on:refresh="getAllEnquiryReservations()"
      />
      <ReservationConfirm
        :reservationData="reservationData"
        v-on:refresh="getAllEnquiryReservations()"
      />
    </div>

    <EnquiryReservationFloatBtns
      :theFilterData="enquiryReservations.filterData"
      v-on:search="search($event)"
    />
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import EnquiryReservationsTable from "@/components/reservations/enquiryReservations/EnquiryReservationsTable.vue";
import EnquiryReservationDelete from "@/components/reservations/enquiryReservations/EnquiryReservationDelete.vue";
import EnquiryReservationFloatBtns from "@/components/reservations/enquiryReservations/EnquiryReservationFloatBtns.vue";
import ReservationConfirm from "@/components/reservations/reservations/ReservationConfirm.vue";
import Pagination from "@/components/general/Pagination.vue";
import EnquiryReservations from "@/models/reservations/enquiryReservations/EnquiryReservations";
import EnquiryReservation from "@/models/reservations/enquiryReservations/EnquiryReservation";
import Reservation from "@/models/reservations/reservations/Reservation";

export default {
  name: "EnquiryReservations",
  components: {
    PreLoader,
    ExceptionWithImg,
    EnquiryReservationsTable,
    EnquiryReservationDelete,
    EnquiryReservationFloatBtns,
    ReservationConfirm,
    Pagination,
  },
  data() {
    return {
      isClient: false,
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken,
      language: localStorage.getItem("userLanguage") || "ar",
      enquiryReservations: new EnquiryReservations(),
      enquiryReservationData: new EnquiryReservation(),
      reservationData: new Reservation(),
    };
  },
  methods: {
    async setUserType() {
      if (this.$store.getters.userData.clientPersonalData) {
        this.isClient = true;
      }
    },
    changePage(page) {
      this.enquiryReservations.pagination.selfPage = page;
      this.getAllEnquiryReservations();
    },
    search(data) {
      this.enquiryReservations.filterData.fillData(data);
      this.enquiryReservations.enquiryReservationsData = null;
      this.getAllEnquiryReservations();
    },
    setReservationData(data) {
      this.reservationData.setInitialValue();
      this.reservationData.enquiryReservationToken =
        data.enquiryReservationToken;
    },
    async getAllEnquiryReservations() {
      this.isLoading = true;
      if (this.isClient) {
        this.enquiryReservations.filterData.clientToken =
          this.$store.getters.userData.clientPersonalData.clientToken;
      }
      try {
        const response =
          await this.enquiryReservations.enquiryReservation.getAllEnquiryReservations(
            this.language,
            this.userAuthorizeToken,
            this.enquiryReservations.pagination,
            this.enquiryReservations.filterData
          );
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.enquiryReservations.fillData(response.data);
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.enquiryReservations.enquiryReservationsData = null;
          this.exceptionMsg = response.data.msg;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.enquiryReservations.enquiryReservationsData = null;
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.enquiryReservations.enquiryReservationsData = null;
          this.exceptionMsg = response.data.msg;
        }
      } catch (error) {
        this.enquiryReservations.enquiryReservationsData = null;
        this.exceptionMsg = this.$t("errorCatch");
      }
      this.isLoading = false;
    },
  },

  computed: {},
  async created() {
    this.setUserType();
    this.getAllEnquiryReservations();
  },
};
</script>
