import PaginationData from "@/models/general/PaginationData";
import EnquiryReservation from "@/models/reservations/enquiryReservations/EnquiryReservation";
import EnquiryReservationsFilter from "@/models/reservations/enquiryReservations/EnquiryReservationsFilter";

export default class EnquiryReservations {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.status = 0;
    this.msg = "";
    this.pagination = new PaginationData();
    this.enquiryReservationsData = [];
    this.enquiryReservation = new EnquiryReservation();
    this.filterData = new EnquiryReservationsFilter();
  }
  fillData(data) {
    this.status = data.status;
    this.msg = data.msg;
    this.pagination.fillData(data.pagination);
    this.enquiryReservationsData = data.enquiryReservationData;
  }
}
